<template>
  <v-container>
    <v-row>
      <v-col cols='12'>
        <v-card>
          <v-card-title>
            Data Pengguna 
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
          </v-card-title>
          <v-container>
            <v-data-table
              :headers="headers"
              :items="dataTable"
              :options.sync="options"
              :server-items-length="totalDataTables"
              :loading="loading"
              :search="search"
              :footer-props="{
                'items-per-page-options' : [ 5, 10, 15, 100 ]
              }"
              class="elevation-1"
            >
            <template v-slot:top>
                <v-dialog v-model="dialogVerification" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">Apakah anda yakin ingin memverifikasi data ?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeVerification">Batal</v-btn>
                      <v-btn color="blue darken-1" text @click="verificationItemConfirm">Verifikasi</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="520px">
                  <v-card>
                    <v-card-title class="headline">Apakah anda yakin ingin menghapus iklan ?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Batal</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">Hapus</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </template>
            <template v-slot:[`item.featured_image`]="{ item }">
              <v-img
                :lazy-src="item.featured_image"
                max-height="150"
                max-width="250"
                :src="baseUrl + item.featured_image"
              ></v-img>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="verificationItem(item)"
              >
                mdi-check
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn>
            </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  // import { validationMixin } from 'vuelidate'
  // import { required, maxLength,minLength, email } from 'vuelidate/lib/validators'
  import axios from 'axios'
  
  export default {
    // mixins: [validationMixin],

    // validations: {
    //   title: { required, maxLength: maxLength(100) ,minLength: minLength(3)},
    //   email: { required, email },
    //   select: { required },
    //   checkbox: {
    //     checked (val) {
    //       return val
    //     },
    //   },
    // },
    data () {
      return {
        baseUrl : 'https://api.awakkerja.com/public/',
        baseUri : 'Api/UD/verificationUsers',
        //start snackbar
        multiLine: true, 
        snackbar : false, 
        message : false, 
        //end snackbar

        //start datatable
        search  : '', 
        dataTable : [], 
        totalDataTables : 0, 
        loading: true, 
        options: {}, 
        headers: [ 
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Title', value: 'title' },
          { text: 'Phone Number', value: 'phone_number' },
          { text: 'Whatsapp', value: 'whatsapp' },
          { text: 'Username', value: 'username' },
          { text: 'First Name', value: 'first_name' },
          { text: 'Last Name', value: 'last_name' },
          { text: 'Date', value: 'date_register' },
          { 
            text: 'Verifikasi', 
            value:'actions',
            sortable : false
          }
        ],
        dialogDelete: false, 
        dialogVerification  : false,
        //end datatable

        //start form
        rules: [
          value => !value || value.size < 200000 || 'Gambar tidak boleh lebih dari 200 KB!',
        ],
        // title: '', 
        // key_id : '',
        // image   : '',
        buttonEdit : false,
        // idMasterKategoriErrors : '',
        // imageErrors: '',
        formErrors : {},
        formData : {
          title : '',
          key_id : '',
          image : null,
        },
        memuat : false,
        statusImage : false,
        // email: '',
        // select: null,
        // items: [
        //   'Item 1',
        //   'Item 2',
        //   'Item 3',
        //   'Item 4',
        // ],
        // checkbox: false,
      }
    },
    computed : {
      
    },
    watch: {
      formData : {
        handler(val){
          if(val.image !==null && typeof val.image !=='undefined'){
            if(val.image.size<200000){
              this.statusImage = true
            }else{
              this.statusImage = false
            }
          }else{
            val.image = null //tetapkan nilai null pada gambar agar variabel gambar tidak hilang
          }
        // console.error('value',val.image)
        },
        deep : true,
      },
      //Start Datatable
      options: {
        handler () {
          this.setApiData()
        },
        deep: true,
      },
      search : {
        handler(){
          this.setApiData()
          this.options.itemsPerPage = 10
          this.options.page = 1
        },
        deep : true,
      },
      dialogVerification (val){
        val || this.closeVerification()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      //End Datatable
    },
    mounted () {
      //Start Datatable
      this.setApiData()
      //End Datatable
      this.checkLogin()
    },
    methods: {
      checkLogin(){
        //check Login
        let vm = this
        setTimeout(function(){
          axios({
            method : 'get',
            url  : vm.baseUrl+'Api/UD/Auth/checkToken',
            headers : {
              'Authorization' : vm.$session.get('jwt')
            }
          }).then(function(response){
            if(response.data.status!==200){
              vm.$session.destroy()
              vm.$router.push('/')
            }
          })
        },1000)
        //end check login
      },
      //start form
      onChangeImage(e){
        if(typeof e !=='undefined'){
          this.formData.image = e
          this.formErrors.image = ''
          if(e.size<200000){
            this.statusImage = true
          }else{
            this.statusImage = false
          }
        }
      },
      submit () {
        this.memuat = true
        this.formErrors.image = ''
        this.message = ''
        let valid = 1
        let form_data = new FormData()
        for(const key in this.formData){
          if(key == 'key_id'){
            if(this.buttonEdit){
              form_data.append('key_id',this.formData['key_id'])
              if(key=='image'){
                if(this.formData.image !== '' && typeof this.formData.image !=='undefined'){
                  if(this.statusImage){
                    form_data.append('image',this.formData.image,this.formData.image.name)
                    valid *=1
                  }else{
                    this.formErrors.image = "Periksa ketentuan gambar !"
                    valid *= 0
                  }
                }
              }
              valid *= 1
            }
          }else if(key =='image'){
            if(this.formData.image !== null && typeof this.formData.image !=='undefined'  && this.formData !==null){
              if(this.statusImage){
                form_data.append('image',this.formData.image,this.formData.image.name)
                valid *=1
              }else{
                this.formErrors.image = "Periksa ketentuan gambar !"
                valid *= 0
              }
            }else{
              if(!this.buttonEdit){
                valid *= 0
                this.formErrors.image = "Harap pilih Gambar !"
              }
            }
          }else{
            form_data.append(key,this.formData[key]) 
          } 
        }
        this.memuat = false
        if(valid){
          this.memuat = true
          let vm = this
          axios({
            method: 'post',
            url: vm.baseUrl+'Api/UD/categories/save',
            data: form_data,
            headers : {
            'Authorization' : vm.$session.get('jwt'),
            'Content-Type': 'multipart/form-data'
            },
          })
          .then(function (response) {
            vm.snackbar = true
            vm.message = response.data.message
            if(response.data.status){
              vm.memuat = false
              // window.fileInputForm.reset();
              vm.clear()
              vm.setApiData()
              vm.buttonEdit = false
              // vm.formErrors = {}
            }else{
              vm.memuat = false
              vm.formErrors = response.data.formErrors
            }
          }) 
        }else{
            this.message = 'Maaf, Periksa Formulir!'
            this.snackbar = true
        }
      },
      clear () {

        for(let k in this.formData){
          this.formData[k] = null
        }
      },
      batalEdit (){
        this.title = ''
        this.formData.key_id = ''
        this.buttonEdit = false
      },
      //end form

      //start table
      setApiData (){
        this.loading = true
        this.getData().then(response=>{
          this.dataTable = response.data.rows
          this.totalDataTables = response.data.totalRows
          this.loading = false
        console.log(response);
        })
      },
      getData (){
        return new Promise((resolve,reject)=>{

          let vm = this;
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
          axios({
            method: 'get',
            url: vm.baseUrl+vm.baseUri,
            headers : {
                'Authorization' : vm.$session.get('jwt')
            },
            params: {
              sortBy       : sortBy,
              sortDesc     : sortDesc,
              page         : page,
              itemsPerPage : itemsPerPage,
              search       : (this.search!='')?this.search:false
            }
          })
          .then(function (response) {
            if(response.status===200){
              resolve(response)
            }else{
              reject('Terjadi Kesalahan, Hubungi programmer')
            }
          })
        })
      },
      verificationItem (item) {
        this.formData.key_id = item.actions
        this.dialogVerification = true
      },
      verificationItemConfirm(){
        let vm = this
        axios({
          method : 'get',
          url    : vm.baseUrl+vm.baseUri+'/verification/'+vm.formData.key_id,
          headers : {
                'Authorization' : vm.$session.get('jwt')
            },
        }).then(function(response){
            vm.snackbar = true
            vm.message = response.data.message
            vm.setApiData()
        })
        vm.closeVerification()
      },
      deleteItem (item) {
        this.formData.key_id = item.actions
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        let vm = this
        axios({
          method : 'get',
          url    : vm.baseUrl+vm.baseUri+'/delete/'+vm.formData.key_id,
          headers : {
                'Authorization' : vm.$session.get('jwt')
            },
        }).then(function(response){
          if(response.status==200){
            vm.snackbar = true
            vm.message = response.data.message
            vm.setApiData()
          }else{
            vm.snackbar = true
          }
        })
        this.closeDelete()
      },
      closeVerification (){
        this.clear()
        this.dialogVerification = false
      },
      closeDelete () {
        this.dialogDelete = false
      },
      initialize (){
        this.search = false
      }
    },
  }
</script>